.blog-featured {

	.section-inner {
		padding-top: $blog-featured--padding-t__mobile;
		padding-bottom: $blog-featured--padding-b__mobile;
	}
}

.blog-featured-inner {

	// Affects only blog-featured full
	> .blog-featured-content + .blog-featured-figure,
	> .blog-featured-figure + .blog-featured-content {
		margin-top: $blog-featured--inner-padding-v_mobile;
	}

	> .blog-featured-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

.blog-tags {
	margin-top: 0.75rem;
}

$tag-colors: (
    color-one: 1,
  	color-two: 2,
    color-three: 3,
    color-four: 4,
		color-five: 5
);

@each $colorname, $color in $tag-colors {
  .blog-tag-subject--#{$colorname} {
    background-color: get-color(secondary, $color);
  }
}

.blog-tag-subject {
	@include font-size(xxxs);
	cursor: pointer;

	margin-right: 8px;
	
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;

	border-radius: 9999px;

	.secondary-color-one {
		background-color: get-color(secondary, 1);
	}

	.secondary-color-two {
		background-color: get-color(secondary, 2);
	}

	.secondary-color-three {
		background-color: get-color(secondary, 3);
	}

	.secondary-color-four {
		background-color: get-color(secondary, 4);
	}
}

.blog-article-tag {
	display: flex;
}

.blog-article-title {
	
}

@include media( '<=medium' ) {

	.blog-featured {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $blog-featured--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $blog-featured--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.blog-featured {

		.section-inner {
			padding-top: $blog-featured--padding-t__desktop;
			padding-bottom: $blog-featured--padding-b__desktop;
		}
	}

	.blog-featured-inner {

		// Affects only hero full
		> .blog-featured-content + .blog-featured-figure,
		> .blog-featured-figure + .blog-featured-content {
			margin-top: $blog-featured--inner-padding-v_desktop;
		}
	}
}
